var currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : null;
document.documentElement.setAttribute('data-theme', currentTheme);

new WOW().init();
$(document).ready(function () {
    
    // if (currentTheme) {
    //     document.documentElement.setAttribute('data-theme', currentTheme);

    //     if (currentTheme === 'dark') {
    //         $('#mode-switcher').html("<i class='fas fa-sun'></i> <span>{{__('profile.nav.light_mode')}}</span>").data('mode', 'light');
    //     }
    //     else
    //     {
    //         $('#mode-switcher').html("<i class='fas fa-moon'></i> <span>' + {{__('profile.nav.dark_mode')}}</span>").data('mode', 'dark');
    //     }
    // }
    $("tr.table-event").click(function(){
        if (!$(this).next(".sessions").find(".sessions-items").hasClass("active"))
        {
            $(this).next(".sessions").slideToggle(10);

                $(this).next(".sessions").find(".sessions-items").addClass("active");

        }
        else
        {
            $(this).next(".sessions").find(".sessions-items").toggleClass("active");
            $(this).next(".sessions").slideToggle(10);
        }
    });
    $("tr.table-event").hover(function(){

        $(this).addClass("active");
    },function(){
        $(this).removeClass("active");
    });
    $(".table-event-more .fa-chevron-down").click(function(){
        $(this).parent().parent().next('.table-laps').toggleClass("active");
        $(this).toggleClass("active");
    });
    $(".nav-link").click(function (e) {

        e.preventDefault();

        $(".nav").toggleClass("active");
    });
    $(".mobile-nav-link").click(function (e) {

        e.preventDefault();

        $(".mobile-nav").toggleClass("active");
        btn = $(this).find('.fas');
        if($(btn).hasClass('fa-bars'))
        {
            $(btn).removeClass('fa-bars').addClass('fa-times');
        }
        else $(btn).removeClass('fa-times').addClass('fa-bars');
    });


    // $("#add-driver").click(function(){
    //     $(this).parent().find("input[type=text]:last").after("<input type=\"text\" name=\"driver\" ")
    // });
    $(".login-form-item__input").focusin(function () {
        if ($(this).val().length === 0) {
            $(this).parent().addClass("active");
        }
    }).focusout(function () {
        if ($(this).val().length === 0) {

            $(this).parent().removeClass("active");
        }
    });
    $(document).on("focusin", ".form-item__input, .form-item__textarea", function () {
        
        if ($(this).val().length === 0) {
            $(this).parent().addClass("active");
        }
    }).on("focusout", ".form-item__input, .form-item__textarea",function () {
        if ($(this).val().length === 0) {

            $(this).parent().removeClass("active");
        }
    });


    $(".tab-nav-item").click(function(e){
        if(!$(this).data("session")) {window.open($(this).attr('href'), '_blank').focus(); return;};
        e.preventDefault();
        $(".tab-nav-item.active").toggleClass("active");
        $(this).toggleClass("active");
        $(".tab-session.active").toggleClass("active");
        console.log("." + $(this).data("session"));
        $("." + $(this).data("session")).toggleClass("active");
    });
    $("#header-notification").click(function(e){
        e.preventDefault();
        // alert("click");
        $(this).parent().children(".content").toggleClass("active");
        $.get('/markAsRead');
    });
    $("#nav-user").click(function(e){
        e.preventDefault();
        // alert("click");
        $(this).parent().children(".content").toggleClass("active");
        $.get('/markAsRead');
    });



    $(document).click(function(event) {
        $target = $(event.target);
        if(!$target.closest('.header-notification > .content').length &&
            !$target.closest('#header-notification').length &&
            $('.header-notification > .content').hasClass("active"))
            {
                $('.header-notification > .content').toggleClass('active');
            }
      });
    $(document).click(function(event) {
        $target = $(event.target);
        if(!$target.closest('.nav-user > .content').length &&
            !$target.closest('#nav-user').length &&
            $('.nav-user > .content').hasClass("active"))
            {
                $('.nav-user > .content').toggleClass('active');
            }
      });
    $(".profile-er-more__link").click(function(e){
        e.preventDefault();
        $(this).toggleClass('toggle');
        $('.profile-er-graph').toggleClass('toggle');
    });
    $(".profile-ext-stat__btn").click(function(e){
        e.preventDefault();
        $(this).find("i").toggleClass('toggle');
        $('.profile-ext-stat').toggleClass('toggle');
    });
    $("#mode-switcher").click(function(e){
        e.preventDefault();

    });
});
